@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Base Background & Text */
    --background: #020617;          /* Custom deep dark-blue background */
    --foreground: #FFFFFF;          /* White for main text */

    /* Chart Colors (Tailwind equivalents) */
    --chart-1: #f3ca45;             /* amber-400 */
    --chart-2: #2cbdad;             /* emerald-500 */
    --chart-3: #f44336;             /* red-500 */
    --chart-4: #3b82f6;             /* blue-500 */
    --chart-5: #9333ea;             /* purple-500 */

    /* Card & Popover */
    --card: #1e293b;                /* Tailwind slate-800 */
    --card-foreground: #ffffff;     /* White */
    --popover: #0F172A;             /* Slate-900 */
    --popover-foreground: #ffffff;  /* White */

    /* Primary & Secondary */
    --primary: #1A1C34;             /* Custom dark-blue (same as popover, for primary elements) */
    --primary-foreground: #ffffff;  /* White */
    --secondary: #0369A1;           /* Tailwind sky-700 */
    --secondary-foreground: #ffffff;/* White */

    /* Muted, Accent, & Destructive */
    --muted: #64748b;               /* Tailwind slate-500 */
    --muted-foreground: #f1f5f9;      /* Tailwind slate-100 */
    --accent: #0369A1;              /* Tailwind sky-700 (reused as accent) */
    --accent-foreground: #ffffff;    /* White */
    --destructive: #f44336;          /* Tailwind red-500 */
    --destructive-foreground: #FFFFFF;/* White */
    
    /* Success Color (Added from globalStyles) */
    --success: #10E293;             /* Added based on Sentry styles */

    /* Border, Input, & Ring */
    --border: #64748b;              /* Tailwind slate-500 */
    --input: #64748b;               /* Tailwind slate-500 */
    --ring: #0369A1;                /* Tailwind sky-700 */

    /* Supporting & Structural Colors */
    --radius: 0.5rem;
    --text-color: #FFFFFF; /* Consider removing if --foreground is always used */

    /* Sidebar Variables */
    --sidebar-background: #1e293b;           /* Tailwind slate-800 */
    --sidebar-foreground: #f1f5f9;           /* Tailwind slate-100 */
    --sidebar-primary: #0369A1;              /* Tailwind sky-700 */
    --sidebar-primary-foreground: #ffffff;   /* White */
    --sidebar-accent: #364156;              /* Custom dark-slate (akin to Tailwind slate-700) */
    --sidebar-accent-foreground: #f1f5f9;     /* Tailwind slate-100 */
    --sidebar-border: #64748b;              /* Tailwind slate-500 */
    --sidebar-ring: #0369A1;                /* Tailwind sky-700 */
  }

  /* Unique Animations (not duplicated in Tailwind config) */
  @keyframes twinkle {
    0%, 100% { opacity: 0.2; }
    50% { opacity: 1; }
  }
  .animate-twinkle {
    animation: twinkle 2s infinite;
  }

  @keyframes progress {
    0% { width: 0%; }
    100% { width: 100%; }
  }
  .animate-progress {
    animation: progress 30s linear;
  }
  
  /* Base element styling */
  * {
    @apply border-border selection:bg-white/10 selection:text-white;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html {
    @apply scroll-smooth;
    text-rendering: optimizeLegibility;
  }

  body {
    @apply bg-background text-foreground overflow-x-hidden;
    margin: 0; /* Ensure margin is 0 even if preflight is modified */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Nunito", sans-serif; /* Ensure font is applied */
    /* Apply background gradient */
    background: linear-gradient(135deg, var(--primary), var(--background));
    background-attachment: fixed; /* Keep gradient fixed during scroll */
  }
  
  a {
    @apply text-inherit no-underline;
  }

  /* Custom Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 0.8rem;
  }

  body::-webkit-scrollbar-track {
    background: var(--primary);
  }

  body::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 10px;
    border: 3px solid var(--primary);
  }

  body::-webkit-scrollbar-thumb:hover {
    background-color: var(--secondary); /* Can adjust hover color if needed */
  }

  /* Firefox scrollbar styles */
  /* Apply directly to html or body if preferred over global */
  html { 
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--primary);
  }

  /* Enhance scrollbars for larger screens (optional) */
  @media (min-width: 768px) {
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
  }
}


@layer components {
  /* Sentry Feedback Styles */
  #sentry-feedback {
    --background: var(--primary);
    --background-hover: var(--primary); /* Adjust hover if needed */
    --foreground: var(--foreground);
    --error: var(--destructive);
    --success: var(--success);
    --border-radius: 0.5rem;
    --accent-background: var(--secondary);
    --accent-background-hover: var(--secondary); /* Adjust hover if needed */
    --font-family: "Nunito", sans-serif;
    --font-size: 1rem;
    --form-border-radius: 0.5rem;
    /* Position styles from original global */
    position: fixed;
    bottom: 0.5rem;
    left: auto;
    right: 0.5rem;
    top: auto;
    z-index: 999;
  }

  /* Tooltip Styles */
  div[id$="Tip"] {
    color: var(--foreground);
    background: var(--primary);
    --rt-opacity: 0.99;
  }
  
  /* Code Block Styles */
  pre[class*="language-"],
  code[class*="language-"] {
    color: #d6deeb; /* Consider using a foreground variable? */
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    tab-size: 4;
    hyphens: none;
  }

  pre[class*="language-"] {
    @apply p-4 overflow-auto rounded-md border;
    background-color: #1a202c; /* Consider a card/background variable? */
    border-color: #4a5568; /* Consider a border variable? */
  }

  code[class*="language-"] {
    @apply px-1 py-0.5 rounded text-sm;
    background-color: #1a202c; /* Consider a card/background variable? */
    border: 1px solid #4a5568; /* Consider a border variable? */
    white-space: normal;
  }
}

@layer utilities {
  .scrollbar-none {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .scrollbar-none::-webkit-scrollbar {
    display: none;
  }

  .glass-card {
    @apply bg-slate-medium/80 backdrop-blur-sm border border-white/10 shadow-lg;
  }
  
  .text-gradient {
    @apply bg-gradient-to-r from-sky-500 via-purple-500 to-sky-500 bg-clip-text text-transparent;
  }
  
  /* Add visual enhancements for larger screens */
  .large-screen-container {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
  }
  
  .content-glow {
    @apply relative;
  }
  
  .content-glow::before {
    content: "";
    @apply absolute -inset-1 bg-gradient-to-r from-purple/20 via-sky/30 to-purple/20 rounded-xl blur-xl opacity-70 -z-10;
  }
  
  .hover-lift {
    @apply transition-all duration-300 hover:-translate-y-1 hover:shadow-lg;
  }
  
  /* Enhanced image hover effects */
  .img-hover-zoom {
    @apply overflow-hidden;
  }
  
  .img-hover-zoom img {
    @apply transition-transform duration-500 ease-in-out;
  }
  
  .img-hover-zoom:hover img {
    @apply transform scale-110;
  }
  
  /* Gradient overlay effects */
  .gradient-overlay {
    @apply relative overflow-hidden;
  }
  
  .gradient-overlay::after {
    content: "";
    @apply absolute inset-0 bg-gradient-to-t from-slate-dark to-transparent opacity-0 transition-opacity duration-300;
  }
  
  .gradient-overlay:hover::after {
    @apply opacity-60;
  }
  
  /* Content layout helpers */
  .split-layout {
    @apply flex flex-col md:flex-row;
  }

  .split-layout-reverse {
    @apply flex flex-col md:flex-row-reverse;
  }
  
  /* Animation utilities */
  .fade-in {
    animation: fadeIn 0.5s ease-in forwards;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .staggered-fade > * {
    opacity: 0;
    animation: fadeIn 0.5s ease-in forwards;
  }
  
  .staggered-fade > *:nth-child(1) { animation-delay: 0.1s; }
  .staggered-fade > *:nth-child(2) { animation-delay: 0.2s; }
  .staggered-fade > *:nth-child(3) { animation-delay: 0.3s; }
  .staggered-fade > *:nth-child(4) { animation-delay: 0.4s; }
  .staggered-fade > *:nth-child(5) { animation-delay: 0.5s; }
}